 
 .paketStart {
    margin-top: 30px;
 }

.paketStart .paket {
    width: 1269px;
    height: 177px;
    top: 408px;
    left: 29px;
    gap: 0px;
    /* opacity: 0px; */
    background-color:#F5F8FB;
    background-image:url(../../../../images/wimax/paketStartUzor.png);
    background-repeat: no-repeat;
    border-radius:20px;
    margin-bottom: 15px;
}


.paketStart .paket {
    display: flex;
    justify-content: space-around;
    align-items:center;
}

.paketStart .paket .paketText {
    display: flex;
    align-items: center;
}
.paketStart .paket .paketText h3 {
    font-family: 'Roboto';
    font-size: 28px;
    font-weight: 500;
    line-height: 32.81px;
    text-align: left;
    color: #ffffff;
    padding-right: 40px;
}
.paketStart .paket .paketPrice{
    display: flex;
    align-items: center;
}
.paketStart .paket .paketPrice h4 {
    font-family: 'Inter';
    font-size: 38px;
    font-weight: 700;
    line-height: 45.99px;
    text-align: center;
    color: #252525;
}
.paketStart .paket .paketPrice span {
    font-family: 'Roboto';
    font-size: 22px;
    font-weight: 500;
    line-height: 25.78px;
    text-align: left;
    color: #252525;
}

.paketStart .paket .paketButton button{
    width: 174px;
    height: 46px;
    gap: 0px;
    border: 1px;
    /* opacity: 0px; */
    background-color:#EE6969;
    border-radius:50px;
}
.paketStart .paket .paketButton button{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color:#ffffff;    
}

.Note {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 300;
    line-height: 21.09px;
    text-align: left;
    padding-left: 30px;
    padding-top: 20px;
    color: #000000;
}