.tarrifs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tarrifs .Tv {
    width: 316px;
    height: 702px;
    top: 892px;
    left: 255px;
    gap: 0px;
    /* opacity: 0px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius:10px;
    background-color: #dddddd;
    margin: 10px;
}
.tarrifs .Tv .header {
    display: grid;
    justify-content: center;
    background-color:#eeeeee;
    padding-top: 15px;
    padding-bottom: 10px;
}

.tarrifs .Tv .header h2 {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 300;
    line-height: 35.16px;
    text-align: center;
}
.tarrifs .Tv .header img{
    width: 210px;
    height: 54px;
    top: 951px;
    left: 311px;
    gap: 0px;
    /* opacity: 0px; */

}
.tarrifs .Tv .content {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    /* background-color: #dddddd; */
}
.tarrifs .Tv .content label {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 300;
    line-height: 35.16px;
    text-align: center;
    color: #474747;

}
.tarrifs .Tv .content h4 {
    font-family: 'Roboto';
    font-size: 50px;
    font-weight: 600;
    line-height: 58.59px;
    color: #474747;
    padding-top: 5px;
}
.tarrifs .Tv .content span {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 300;
    line-height: 21.09px;
    text-align: center;
    color: #474747;
}
.tarrifs .Tv .content .options1 {
    padding-bottom: 15px;
}
.tarrifs .Tv .content .options3 {
    padding-top: 45px;
    padding-bottom: 25px;
}
.tarrifs .Tv .content .footer span {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 300;
    line-height: 23.44px;    
    color: #474747;
}
.tarrifs .Tv .content .footer img {
    padding-right: 5px;
    padding-bottom: 5px;
}

.tarrifs .Tv .content .button{
    display: grid;
    justify-content: center;
}
.tarrifs .Tv .content .button button{
    width: 230px;
    height: 59px;
    gap: 0px;
    /* opacity: 0px; */
    border-radius: 50px;
    background-color: #FF8A00;
    border: none;

    font-family: 'Noto Sans';
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;


}
.tarrifs .Tv .content .footer {
    padding-top: 20px;
    padding-bottom: 9px;
}
.tarrifs .requestText {
    display: grid;
    padding-left: 20px;
    width: 48%;
}
.tarrifs .requestText h3 {
    font-family: 'Roboto';
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    text-align: left;
    color: #000000;
    padding-left: 15%;
}

@media only screen and (max-width:980px) {
    .tarrifs .requestText {
        width: 90%;
    }
    .tarrifs .requestText img {
        width: 100%;
    }

    .tarrifs .requestText h3 {
        font-size: 30px;
  }
    .tarrifs .requestText p {
        font-size: 18px !important;
  }


}

.tarrifs .requestText p {
    font-family: 'Roboto';
    color: #666666;
    font-size: 26px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
    padding-left: 15%;
}
