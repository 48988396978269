.wimax {
    background-color:#ffffff;
}

.wimax .fonHeader {
    height: 391px;
    background-image: url(../images/wimax/fon_wimax.png);
    
}

@media only screen and (max-width:1400px) {
    .wimax .fonHeader .flex .imgRouter {
       display: none;
    }
    .wimax .fonHeader {
        display: grid;
        align-items: center;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}


@media only screen and (max-width:800px) {
    .wimax .fonHeader .headerText h4 {
        font-size: 30px !important;
        line-height: 30px !important;
    }
    .wimax .fonHeader .headerText h1 {
        font-size: 60px !important;
        line-height: 30px !important;
    }
    .wimax .fonHeader .flex .logo {
        padding-bottom: 10px;
        width: 150px;
    }
    .wimax .fonHeader .flex .logo {
        padding-bottom: 0px !important;
    }
    .wimax .fonHeader .flex .headerText {
        padding-bottom: 0px !important;
    }
}
@media only screen and (max-width:500px) {
    .wimax .fonHeader .flex {
        display: grid !important;
    }
    .wimax .fonHeader .flex .logo {
        padding-bottom: 20px !important;
    }
}

.wimax .fonHeader .flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.wimax .fonHeader .headerText h4 {
    font-family: Roboto;
    font-size: 50px;
    font-weight: 300;
    line-height: 60px;
    text-align: left;
    color:#ffffff;
}

.wimax .fonHeader .headerText h1 {
    font-family: Roboto;
    font-size: 75px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color:#ffffff;
}
.wimax .fonHeader .flex .imgRouter {
    padding-top: 95px;
}