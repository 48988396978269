*:focus
{
   outline: none;
}
.blockTarif .card .options select {
    height: 41px;
    border-radius: 6px;
    border: 0.5px solid #949494;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.58px;
    text-align: left;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
}
.blockTarif .card .options .footer .priceOptom{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.blockTarif .card .options .footer .priceOptom h3 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.31px;
    text-align: left;
    color: #1A1A1A;

}
.blockTarif .recom .options .footer .priceOptom h3 {
    color: #ffffff;
}
.blockTarif .card .options .footer .priceOptom h2 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 500;
    line-height: 36.31px;
    text-align: left;
    color: #A0A0A0;
    position: relative;
}

.blockTarif .card .options .footer .priceOptom h2 span::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -5px;
    width: 105%;
    height: 100%;
    border-bottom: 2px solid red;
    transform: rotate(-13deg);
    transform-origin: 0 0;
}
