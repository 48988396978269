.myHosting {
    background-color:#ffffff;
}

.fonHosting {
    height: 402px;
    top: 111px;
    gap: 0px;
    /* opacity: 0px; */
    background-color:#1573FFCC;
    background-image:url(../images/hosting/fonHosting1.png),
    url(../images/hosting/fonHosting2.png);
    background-repeat:no-repeat,
    no-repeat;
    background-size: auto, auto;
    background-position:
    90% 50%, left;
    display: flex;
    align-items: center;

    
}

.fonHosting h1 {
    font-family: Roboto;
    font-size: 96px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    padding-left: 20px;
    padding-left: 50px;
    color: #ffffff;
}

@media only screen and (max-width: 800px) {
    .fonHosting {
        height: 260px;
        background-size: 320px, 200px;
    }
    .fonHosting h1 {
        font-size: 60px;
    }
  }
