.server-calculator {
    /* max-width: 820px; */
    margin: 0 auto;
    padding: 25px;
    font-family: Arial, sans-serif;
    display: flex;
    width: 100%;
    background-color: #ffffff;
    border-radius: 15px;
  }
  
  @media only screen and (max-width: 1400px) {
    .server-calculator {
      flex-wrap: wrap;
      justify-content: center;
    }
    .charakter .flex { 
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .resources {
    width: 500px;
  }
  @media only screen and (max-width: 767px) {
    .resources{
      max-width: 80px;
    }
  }
  .resources, .additional-services {
    margin-bottom: 30px;
  }
  
  .additional-services h3 {
    margin-bottom: 15px;
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    color: #434343;
    }
  
  .additional-service {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .additional-service label {
    margin-right: 10px;
  }  
  .pricing {
    background: linear-gradient(180deg, #C4CAD7 0%, #8B98B4 85%);
  }
  
  .pricing h2 {
  font-family: Roboto;
  }
  .pricing span {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 300;
    line-height: 35.16px;
    text-align: left;
    
  }
  .pricing .pricing-text {
    display: grid;
    
  }
  .pricing .pricing-text .disabled::before {
    content: '';
    position: absolute;
    top: 0px;
    left: -6px;
    width: 30%;
    height: 55%;
    border-bottom: 5px solid red;
    transform: rotate(-18deg);
    transform-origin: 0 0;
    color: #FFFFFF80;

}
  
  .pricing select {
    margin: 10px 0;
    padding: 5px;
  }
  
  .pricing .submit-button {
    top: 1309px;
    left: 1189px;
    font-family: Roboto;
  }

  .pricing .range-labels span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #FFFFFF;
  }
  .pricing .change-discount {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.58px;
    text-align: left;
    color: #FFFFFF;

  }
  .pricing .footer-text {
    padding-top: 50px;
  }

  .pricing .footer-text p {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    line-height: 17.58px;
    text-align: left;
    color: #F8F8F8;
  }