.News {
    background: #ffffff;
    padding-top: 20px;
    padding-bottom: 60px;
}

.News .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    align-items: center;
}

.News .title h3 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    color: #4B4B4B;
}
.News .title h4 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    text-align: left;
    color: #010047;
}

.News .cards {
 display: flex;
 justify-content: space-between; 
}

@media only screen and (max-width:1400px) {
    .News .cards {
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
       }
       .title {
        padding: 15px;
       }
       
}

.News .cards .myCard{
    width: 426.99px;
    height: 576px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    border-radius: 15px;
    margin: 10px;
    overflow: hidden;
}

.News .cards .myCard .img{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.News .cards .myCard .img:hover{
        transform: scale(1.1); /* Увеличивает картинку на 10% */
        transition: transform 0.5s ease; /* Плавное увеличение за 0.5 секунды */   
}

.News .date {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #181059;
    padding-left: 23px;
}

.News .cardTitle {
    font-family: Roboto;
    font-size: 28px;
    font-weight: 800;
    line-height: 32.81px;
    text-align: left;
    color: #010047;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 23px;
}
.News .cardText {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    line-height: 19.92px;
    text-align: left;
    color: #181059;
    padding-left: 23px;

}