.tarrifVps .freeForVps h3 {
    font-family: Roboto;
font-size: 30px;
font-weight: 600;
line-height: 35.16px;
text-align: left;
color: #474747;
padding-top: 5%;

}
.tarrifVps .technical {
    background-image: url(../../../../images/vps/Mask.png);
    background-repeat:no-repeat;
    background-position: 80% 100%;
    gap: 0px;
    display: grid;
}
.charakter {
    padding: 5%;
}

.charakter p {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    color: #434343;
    padding-bottom: 30px;
}
.charakter .flex {
   display: flex;
   justify-content: space-between;
}

.calculatePrice {
    background-color: #f5f5f5;
    padding-top: 5px;
    padding-bottom: 30px;
}

.calculatePrice .vps-header  {
    padding-top: 10px;
    padding-bottom: 40px;
}
.calculatePrice .vps-header h2 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: center;
    color: #474747;
}

.calculatePrice .vps-header h3 {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    line-height: 17.58px;
    text-align: center;
    color: #4A4A4A;
}