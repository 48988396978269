.resource-slider {
  margin-bottom: 20px;
  display: flex;
  border-bottom: 1px solid #C7C7C7;
}

.slider-label {
  display: grid;
  justify-content: space-between;
  margin-bottom: 10px;
}
.slider-label .slider-title {
  width: max-content;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
  color: #838181;
  padding-bottom: 10px;

}
.slider-label {
  display: grid;
  justify-content: space-between;
  margin-bottom: 10px;
}
.slider-label .card-option {
  display: flex;
  align-items:center;
}
.slider-card {
  display: grid;
  justify-content: space-between;
  width: max-content;
  padding-left: 15px;
  padding-right: 15px;
}

/* .slider-label .img {
 width: 34px;
} */

.slider-description {
  font-size: 0.8em;
  color: #666;
}

.range-container {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
}

.custom-range {
  --webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #e0e0e0;
  outline: none;
  border-radius: 4px;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: #4285f4;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, 0 0 0 5px #4285f4;
}

.custom-range::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #4285f4;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, 0 0 0 5px #4285f4;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  color: #666;
  font-size: 12px;
}

.slider-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.slider-value input[type="number"] {
  width: 77px;
  height: 34px;
  border-radius: 10px;
  border: 0.5px solid #000000;
  /* opacity: 0px; */
}
.slider-value input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


.slider-value span {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.06px;
  text-align: left;
  color: #979797;
  padding-left: 10px;
  padding-top: 20px;
}
