.sendRequest .banner h3{
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    color: #ffffff;
    padding-top: 5%;
    padding-bottom: 5%;
}

.sendRequest .subTitle p {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    color: #434343;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sendRequest .thisTarrif {
    width: 110%;
    padding: 35px;
    gap: 0px;
    border-radius: 10px;
    border: 1px solid #00ABF4;
    box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);


}
.sendRequest .flex {
    display: flex; 
    justify-content: space-between;
}

@media only screen and (max-width:1400px) {
    .sendRequest .flex {
        display: grid;
        justify-content: center;
    }
    .sendRequest .inputs .addRess {
        flex-wrap: wrap;
    }
    .sendRequest .inputs .addRess .selectSity select{
        margin-bottom: 20px;
        width: 330px !important;
    }
    .sendRequest .inputs .addRess input {
        margin-bottom: 20px;
    }
    .sendRequest .thisTarrif {
        padding: 10px;
    }
}

@media only screen and (max-width:900px) {
    .sendRequest .sendTarrif .thisTarrifOptom .Month {
        width: auto !important;
    }
    .sendRequest .thisTarrif {
        display: grid !important;
        width: auto;
        justify-content: center;
    }
}

@media only screen and (max-width:620px) {
    .sendRequest .sendTarrif .thisTarrifOptom .Month {
        width: 360px !important;
        display: grid !important;
        height: 400px !important;
    }
    .sendRequest .inputs .addRess .strit
    {
        width: 100% !important;
    }
    .sendRequest .inputs .addRess .dom
    {
        width: 100% !important;
    }
    .sendRequest .inputs .addRess .room
    {
        width: 94% !important;
    }
    .sendRequest .flex {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .sendRequest .thisTarrif  {
        width: 100%;
    }
    .sendRequest .thisTarrif1 {
        display: flex;
        flex-direction: column;
    }
    .thisTarrifOptom {
        display: grid;
        justify-content: center;
    }
    .sendTarrif {
    display: ruby;
    justify-content: center;
    }

    .sendRequest .subTitle p {
        font-size: 26px;
    }
    .sendRequest .inputs .nameNumber .name input {
        width: 340px !important;
    }
    .sendRequest .inputs .nameNumber .number input {
        width: 340px !important;
        margin-top: 10px;
    }

    .sendRequest .sendForm .IPTV {
        width: auto !important;
        margin-bottom: 20px;
    }
    .sendRequest .inputs h3 {
        font-size: 26px !important;
    }

}

.sendRequest  {
    padding-bottom: 3%;
}
.sendRequest .thisTarrif span img {
    /* width: 100px; */
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}



.sendRequest .thisTarrif {
    display: flex;
    /* justify-content: space-around; */
    align-items:center;
}
.sendRequest .thisTarrif1 {
    display: flex;
    justify-content: space-around;
    align-items:center;
}

.sendRequest .sendTarrif .thisTarrif .options2 li {
    display: grid;
}
.sendRequest .sendTarrif .thisTarrif .options1 h4 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    line-height: 25.78px;
    text-align: left;
    color: #252525;   
} 
.sendRequest .sendTarrif .thisTarrif .options1 .speed {
    padding-bottom: 22px;
}

.sendRequest .sendTarrif .thisTarrif .options1 span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 18.75px;
    text-align: left;
    color: #252525;
    
}
.sendRequest .sendTarrif .thisTarrif .options2 h4 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    line-height: 25.78px;
    text-align: left;
    color: #252525;    
}

.sendRequest .sendTarrif .thisTarrif .options2 span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 18.75px;
    text-align: left;
    color: #252525;
    
}



.sendRequest .thisTarrif p {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
    line-height: 31.47px;
    text-align: center;
    color: #00ABF4;
}

.sendRequest .sendTarrif .thisTarrif .options3 .price h3 {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.73px;
    text-align: center;
    color: #252525;
    
}
.sendRequest .sendTarrif .thisTarrif .options3 .price .span{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    padding-left: 5px;
    /* text-align: left;     */
    color: #252525;
    
}
.sendRequest .sendTarrif .thisTarrif .options3 .footer a {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 200;
    line-height: 18.75px;
    text-align: center;
    color: #424242;
    padding-top: 25px;
    text-decoration: none;
}
.sendRequest .sendTarrif .thisTarrif .options3 .footer .Button {
    width: 167px;
    height: 41px;
    gap: 0px;
    background-color:#1E1F33;
    border: 0.5px solid #1E1F33;
    border-radius: 10px;
    /* opacity: 0px; */
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 5px;

}
.sendRequest .sendTarrif .thisTarrif .options3 .footer .Button:hover {
    background-color: #ffffff;
    color: #252525;
}

.sendRequest .sendTarrif .thisTarrif .options3 .footer {
    display: grid;
    /* align-items: center; */
}
.sendRequest .sendTarrif .thisTarrif .options3 .price {
    display: flex;
    align-items: center;
}

/* style by thisTarrif Optom */

.sendRequest .sendTarrif .thisTarrifOptom .Month {
    /* width: 902px; */
    width: 110%;
    height: 135px;
    gap: 0px;
    /* opacity: 0px; */
    background-color: #F5F8FB;
    border: 0.5px solid #C7C7C7;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items:center;
    margin-bottom: 10px;
}


.sendRequest .sendTarrif .thisTarrifOptom .Month .discount {
    width: 82px;
    height: 82px;
    gap: 0px;
    /* opacity: 0px; */
    border: 1px solid #ED8001;
    background-color: #ED8001;
    border-radius: 50px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items:center;

}

.sendRequest .sendTarrif .thisTarrifOptom .Month .tarrifName span {
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.2px;
    text-align: left;
    color: #252525;
}

.sendRequest .sendTarrif .thisTarrifOptom .Month .tarrifName h3 {
    font-family: Inter;
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    text-align: left;
    color: #5E5E5E;
}
.sendRequest .sendTarrif .thisTarrifOptom .Month .price {
    display: flex;
    align-items: center;
}

.sendRequest .sendTarrif .thisTarrifOptom .Month .price h3 {
    font-family: Inter;
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    text-align: left;
    color: #5E5E5E;
}
.sendRequest .sendTarrif .thisTarrifOptom .Month .price .span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    padding-left: 5px;
    color: #252525;
}

.sendRequest .sendTarrif .thisTarrifOptom .Month .button {
   display: grid;
}

.sendRequest .sendTarrif .thisTarrifOptom .Month .button  button{
    width: 117px;
    height: 41px;
    gap: 0px;
    border: none;
    border-radius: 10px;
    /* opacity: 0px; */
    background-color: #1E1F33;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}
.sendRequest .sendTarrif .thisTarrifOptom .Month .button  button:hover {
    background-color:#ffffff;
    color: #494949;
}

.sendRequest .sendTarrif .thisTarrifOptom .Month .button a{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 200;
    line-height: 18.75px;
    text-align: center;
    color: #424242;
    text-decoration: none;
    padding-top: 20px;
}




.sendRequest .sendForm .form {
    /* width: 486px; */
    height: 540px;
    top: 443px;
    left: 1177px;
    gap: 0px;
    border-radius: 10px;
    border: 1px solid #00ABF4;
    /* opacity: 0px; */
    background-color: #F5F8FB;
    box-shadow:#0000001A;
    padding: 30px;
    display: grid;
    justify-items: stretch;
    margin-bottom: 20px;

}
.sendRequest .sendForm .form .header h3 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 500;
    line-height: 36.31px;
    text-align: left;
    color: #000000;
    
}
.sendRequest .sendForm .form .header hr {
   border: 1px solid #000000;
    /* color: #000000; */
    
}
.sendRequest .sendForm .form .main label{
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.2px;
    text-align: left;
    
    
}
.sendRequest .sendForm .form .economy label{
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.2px;
    text-align: left;
    
    
}

.sendRequest .sendForm .form .main .priceMonth {
   display: flex;
   justify-content: space-between;  
   padding-bottom: 20px;    
    
}
.sendRequest .sendForm .form .main .priceOptom {
   display: flex;
   justify-content: space-between;      
    
}
.sendRequest .sendForm .form .economy .priceEconom {
    display: flex;
    justify-content: space-between;  
    padding-bottom: 20px;  
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    color: #2AA000;    
    
}
.sendRequest .sendForm .form .economy .summa {
   display: flex;
   justify-content: space-between;      
    
}

.sendRequest .sendForm .form .main .priceMonth span{
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: right;    
    padding-right: 5px;  
    
}
.sendRequest .sendForm .form .main .priceOptom span{
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: right;    
    padding-right: 5px;  
    
}

.sendRequest .sendForm .form .economy .priceEconom span{
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: right;    
}

.sendRequest .sendForm .form .economy .summa span{
    font-family: Inter;
    font-size: 25px;
    font-weight: 700;
    line-height: 30.26px;
    text-align: left;    
    
}

.sendRequest .sendForm .form .main .priceOptom .sumOptom{
   display: grid; 
    
}
.sendRequest .sendForm .form .main .priceOptom .disabled{
  
   color: #C1C1C1;
    
}

.sendRequest .sendForm .form .main .priceOptom .disabled {
    position: relative;
    display: inline-block;
  }
  
  .sendRequest .sendForm .form .main .priceOptom .disabled span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid red;
    transform: rotate(-12deg);
    transform-origin: 0 0;
  }
.sendRequest .sendForm .form .footer{
    display: flex;
    justify-content: flex-end;
}

.sendRequest .sendForm .form .footer input{
    width: 162px;
    height: 48px;
    gap: 0px;
    /* opacity: 0px;    */
    background-color: #ED8001; 
    border-radius: 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    border:none;
}

.sendRequest .sendForm .IPTV {
    /* width: 486px; */
    width: 420px;
    height: 114px;
    top: 1055px;
    left: 1177px;
    gap: 0px;
    border-radius: 10px;   
    border: 1px solid #00ABF4;
    /* opacity: 0px; */
    background-color: #ffffff;
    box-shadow:#0000001A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.sendRequest .sendForm .IPTV .checkBox {
    display: flex;
}

.sendRequest .sendForm .IPTV .checkBox h3 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.31px;
    text-align: right;
    color: #ED8001;
    padding-right: 5px;  
    
}
.sendRequest .sendForm .IPTV .price label {
    font-family: Inter;
    font-size: 25px;
    font-weight: 700;
    line-height: 30.26px;
    text-align: left;
    color: #000000;
    
}
.sendRequest .sendForm .IPTV .price span {
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.2px;
    text-align: left;  
    color: #000000;
    
}

.sendRequest .sendForm .IPTV .checkBox  .custom-switch .form-check-input {
    width: 4em;
    height: 2em;
    /* background-color: #ED8001;  */
  }

  .sendRequest .sendForm .IPTV .checkBox  .custom-switch .form-check-label {
    font-size: 1.5em; /* если нужно изменить размер текста */
  }



 .sendRequest .inputs {
    width: 100%;
    /* height: 100px; */
    background-image: url(../../images/fonSend1.png);
    padding-top: 3%;
    padding-bottom: 5%;
 }
 .sendRequest .inputs h3 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    padding-bottom: 20px;

 }

 .sendRequest .inputs .addRess {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 10px;
 }

 .sendRequest .inputs .addRess .selectSity select  {
    width: 263px;
    height: 56px;
    gap: 0px;
    /* opacity: 0px; */
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 10px;
    border: none;
    margin-right: 20px;
    padding-left: 10px;
 }
 .sendRequest .inputs .addRess .strit  {
    width: 235px;
    height: 56px;
    gap: 0px;
    /* opacity: 0px; */
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 10px;
    border: none;
    margin-right: 20px;
    padding-left: 10px;
 }
 .sendRequest .inputs .addRess .dom  {
    width: 179px;
    height: 56px;
    gap: 0px;
    /* opacity: 0px;     */
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 10px;
    border: none;
    margin-right: 20px;
    padding-left: 10px;
 }
.sendRequest .inputs .addRess .room  {
    width: 179px;
    height: 56px;
    gap: 0px;
    /* opacity: 0px;     */
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 10px;
    border: none;
    padding-left: 10px;

}

.sendRequest .inputs .nameNumber {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items:flex-end;
    margin-top: 50px;
    margin-left: 10px;
}
.sendRequest .inputs h4{
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #454545; 
    padding-left: 10px;   
}
.sendRequest .inputs .nameNumber .name {
    margin-right: 20px;
}
 .sendRequest .inputs .nameNumber .name input {
    width: 464px;
    height: 56px;
    gap: 0px;
    /* opacity: 0px;  */
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 10px;
    border: none;
    padding-left: 10px;

 }
 .sendRequest .inputs .nameNumber .number input {
    width: 384px;
    height: 56px;
    gap: 0px;
    /* opacity: 0px;      */
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
 }
