.table-container {
    margin: 20px;
    font-family: 'Arial, sans-serif';
  }
  
  .tariff-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  @media only screen and (max-width: 500px) {
    .tariff-table th {
     font-size: 16px;
    }
}

  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: right;
  }
  
  .header-cell {
    background-color: #9d9d9d;
    color: #ffffff;
    font-weight: bold;
    text-align: right;

  }
  
  .value-cell {
    text-align: right;
    background-color: #dddfe0;
    border: 1px #fff solid;
  }
  
  .total-header {
    background-color: #feeda7;
    color: #211a22;
  }
  
  .total-value {
    background-color: #feeda7;
    font-weight: bold;
    color: #211a22;
  }
  
  .call-type-header {
  
    background: #39ba5e;
    color: #fff;
    text-align: right;
  }
  
  .call-type {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  .table-container h1 {
    color: #4CAF50;
    text-align: center;
    
  }
  
  .tarrifsNgn ol {
    padding: 30px 30px;
    color: #6f6d6e;
    font-size: 14px;
    font-weight: normal;
  }