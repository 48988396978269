.fontTv {
    height: 555px;
    background-image:
    /* url(../images/tv/kubok.png), */
    url(../images/tv/myTv.png),
    url(../images/tv/tvUzor1.png),
    url(../images/tv/tvUzor.png),
    url(../images/tv/fonTv.png);
    background-position: 
    /* 90% 150%,  */
    80% -80%, 
    94% 50%, 
    59% 9%;
  background-repeat: 
    no-repeat, 
    no-repeat, 
    no-repeat;
  background-size: 
    auto, 
    auto, 
    auto;
    overflow: visible;
    position: relative;
    display: grid;
    align-items: center;
}

@media only screen and (max-width:1150px) {
    .fontTv {
        height: 290px;
     background-position: 90% 105%, 80% 20%, 90% 30%, 59% 11%;
     background-repeat: no-repeat, no-repeat, no-repeat;
     background-size: 265px, 100px, 100px, cover;
    }
    .fontTv .textContent h3 {
        font-size: 40px !important;
        line-height: 15px !important;
    }
    .fontTv .textContent .list ol li {
        font-size: 16px !important;
        line-height: 20px !important;
    }
    .fontTv .fonKubok {
        right: -5% !important;
        bottom: -10% !important;
    }
    .fontTv .fonKubok img {
       width: 55%;
    }
    .fontTv .textContent .button button {
        width: 147px !important;
        height: 49px !important;
        font-size: 14px !important;
    } 
 }
@media only screen and (max-width:500px) {
    .fontTv {
        height: 230px;
     background-position: 90% 105%, 80% 20%, 90% 30%, 59% 11%;
     background-repeat: no-repeat, no-repeat, no-repeat;
     background-size: 180px, 100px, 100px, cover;
    }
    .fontTv .textContent h3 {
        font-size: 26px !important;
        padding-bottom: 10px !important;
    }
    .fontTv .textContent .list ol li {
        font-size: 12px !important;
        line-height: 0px !important;
    }
    .fontTv .textContent .list ol li:before {
        padding-right: 10px !important;
    }
    .fontTv .fonKubok {
        right: -35% !important;
        bottom: -10% !important;
    }
    .fontTv .fonKubok img {
       width: 40%;
    }
    .fontTv .textContent .button button {
        width: 127px !important;
        height: 39px !important;
        font-size: 12px !important;
    } 
    .tvH2 h2 {
        font-size: 30px;
    }
 }


.fontTv .textContent {
    color: #ffffff;
}
.fontTv .textContent h3 {
    font-family: 'Roboto';
    font-size: 64px;
    font-weight: 600;
    line-height: 75px;
    text-align: left;
    padding-bottom: 20px;
}
.fontTv .textContent .list ol li {
    list-style-type: none;
    font-family: 'Roboto';
    font-size: 22px;
    font-weight: 300;
    line-height: 50px;
    text-align: left;

}

.fontTv .textContent .button button {
    width: 228px;
    height: 67px;
    gap: 0px;
    opacity: 0px;
    color: #ffffff;
    background-color:#999aa2;

    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: center;
    border-radius: 50px;
    border: none;
    margin-left: 2%;
    margin-top: 11px;
}

.fontTv .textContent .list ol li:before {
    content: url(../images/tv/VectorTv.png);
    padding-right: 20px;
}

.fontTv .fonKubok {
    position: absolute;
    right: 8%;
    bottom:-17%;
}
.fontTv .textContent .list ol {
    padding-left: 10px;
}