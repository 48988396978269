.custom-navbar {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.custom-navbar img {
  max-width: 100%;
}

.navbar-nav .nav-link {
  color: #333;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .custom-navbar {
    padding: 10px;
  }

  .navbar-nav .nav-link {
    font-size: 0.9rem;
  }

  .underline {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .custom-navbar {
    flex-direction: column;
    align-items: flex-start;
  }   

  .navbar-nav .nav-link {
    font-size: 0.8rem;
    margin: 5px 0;
  }

  .underline {
    font-size: 0.8rem;
  }
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  padding: 10px;
}

.search-results a {
  display: block;
  padding: 5px;
  color: black;
  text-decoration: none;
}

.search-results a:hover {
  background: #f0f0f0;
}