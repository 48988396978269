.Iptv {
    background:#ffffff;
    padding-top: 20px;
    padding-bottom: 60px;
}

.Iptv .myIptv {
    /* width: 1454px; */
    height: 359px; 
    background-image: url(../../images/iptv/fon.png);   
    gap: 0px;    
    border-radius: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
}

@media only screen and (max-width:1250px) {
    .Iptv .myIptv .textTv h2 {
        font-size: 36px !important;
        line-height: 40.72px !important;
    
    }
    .Iptv .myIptv .textTv {
        width: auto !important;
        padding-right: 20px;
    }
    .Iptv .myIptv .textTv span {
        font-size: 18px;
    }
    .Iptv .myIptv .textTv label {
        font-size: 18px;
    }
    .Iptv .myIptv {
        display: grid;
        height: auto;
        background-color:#00a3fe;
        padding-top: 20px;
        padding-right: 40px;
    }
    .Iptv .myIptv .Tv img {
    width: 350px;
    }
    
}

.Iptv .myIptv .textTv {
    width: 700px;
}
.Iptv .myIptv .textTv h2 {
    font-family: Noto Sans;
    font-size: 60px;
    font-weight: 700;
    line-height: 81.72px;
    text-align: left;
    color:#ffffff;

}

.Iptv .myIptv .textTv span {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 300;
    line-height: 27.24px;
    text-align: left;
    color: #ffffff;
}
.Iptv .myIptv .textTv label {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #ffffff;
   
}
.Iptv .myIptv .textTv label span {
    padding-right: 10px;   
}
.Iptv .myIptv .textTv .label {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
   
}