.AllTariff {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}

@media only screen and (max-width:620px) {
  .AllTariff {
    justify-content: center;
}
}

.cyber-start-container {
  width: 266px;
  height: 592px;
  border-radius: 20px;
  border: 1px solid #979797;
  /* opacity: 0px; */
  padding-left: 25px;
  padding-right: 20px;
  padding-top: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.cyber-start-header {
  margin-bottom: 20px;
  font-family: 'Roboto';
  font-size: 28px;
  font-weight: 500;
  line-height: 32.81px;
  text-align: center;
  color: #4A4A4A;
}

.cyber-start-usage {
  margin-bottom: 20px;
}

.cyber-start-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  transform: rotate(0deg);
}

.progress-ring__circle-bg {
  stroke: #d1d1d1;
  box-shadow: #00000040;
}

.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: revert;
}

.cyber-start-text {
  position: absolute;
  font-family: 'Roboto';
  font-size: 25px;
  font-weight: 700;
  line-height: 29.3px;
  text-align: left;
  color: #E80101;
}

.cyber-start-details {
  margin-bottom: 20px;
}

.cyber-start-details ol{
  list-style: none;
}
.cyber-start-details ol > li{
  padding-left: 20px;
  position: relative;
  margin-left: -25px;
}
.cyber-start-details ol > li:before{
  /* content: '✔'; */
  content: url('../../../../images/ok.png');
  position: absolute; top: 0; left: 0;
  color: #181059;
}

.cyber-start-check {
  color: black;
  margin-right: 5px;
}

.cyber-start-price {
  display: grid;
  justify-content: flex-start;
  padding-left: 25px;
}

.cyber-start-container .cyber-start-details li {
  margin-top: 10px;
}

.cyber-start-price .startPrice {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.73px;
  text-align:left;
  color:#4A4A4A;
}

.cyber-start-price .startPriceText {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  color:#4A4A4A;
  text-align:left;
}

.cyber-start-button {
  background-color: #1E1F33;
  border:none;
  width: 174px;
  height: 46px;
  gap: 0px;
  /* opacity: 0px; */
  border-radius: 50px;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 15px;
}

.cyber-start-more {
  font-size: 14px;
  color: #252525;
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
}

.cyber-start-container .cyber-start-detail {
  padding-bottom: 20px;
}

.cyber-start-container .cyber-start-details .span {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 300;
  line-height: 18.75px;
  text-align: left;
  color: #4A4A4A;
}

.cyber-start-container .cyber-start-details h4 {
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 400;
  line-height: 25.78px;
  text-align: left;
  color: #4A4A4A;
  padding-right: 8px;
}

.cyber-start-container .cyber-start-details .flex {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.cyber-start-container .footer {
  margin-top: 20px;
  display: grid;
  justify-content: center;
  text-align: center;
}
