  .pricing-card {
    transition: transform 0.3s, background-color 0.3s, width 0.3s, height 0.3s;
  }
  
  .pricing-card:hover {
    padding-top: 40px;
    background-color: #448FFF;
    color: white;
    transform: scale(1.05);
    width: 268px; 
    height: auto;
  }
  
  .pricing-card .icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .pricing-card .icon {
    font-size: 20px;
    margin-right: 10px;
    transition: opacity 0.3s;
    display: block;
  }
  
  .pricing-card .hover-icon {
    font-size: 20px;
    margin-right: 10px;
    opacity: 0;
    display: none;
    transition: opacity 0.3s;
  }
  
  .pricing-card:hover .icon {
    opacity: 0;
    display: none;
  }
  
  .pricing-card:hover .hover-icon {
    opacity: 1;
    display: block;
  }
  .pricing-card:hover button {
    background-color: #ffffff;
    color: #474747;
  }

  .pricing-card .details {
    margin-bottom: 20px;
  }
  
  .pricing-card .details h3 {
    margin: 10px 0;
    font-family: 'Poppins';
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
    color: #181059;
  }
  .pricing-card:hover .details h3 {
    color: #ffffff;
  }
  
  .pricing-card .details p {
    margin: 10px 0;
    font-family: 'Roboto';
    font-size: 28px;
    font-weight: 300;
    line-height: 32.81px;
    text-align: left;
    color: #181059;

  }
  .pricing-card:hover .details p {
    color: #ffffff;
  }
  
  .pricing-card .details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .pricing-card .details ul li {
    margin: 5px 0;
    display: flex;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 300;
    line-height: 22.5px;
    text-align: left;

  }
  
  .pricing-card .price {
    margin: 20px 0;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
    color: #181059;
  }
  .pricing-card .price span {
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 500;
    line-height: 25.5px;
    text-align: left;

    color: #181059;
  }
  .pricing-card:hover .price{
    color: #ffffff;
  }
  .pricing-card:hover .price span{
    color: #ffffff;
  }
  
  .pricing-card button {
    background-color: #448FFF;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    width: 170.5px;
    height: 43.12px;
    top: 1181.69px;
    left: 394.18px;
    gap: 0px;
    /* opacity: 0px; */
    border-radius: 50px;

  }
  
  .pricing-card  button:hover {
    background-color: #ffffff;
    color: #474747;
  }

  .pricing-card .icon-container {
    display: flex;
    justify-content: center;
  }