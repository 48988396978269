.faqContainer {
    background-color: #ffffff;
    padding: 2.5% 0;
    width: 98vw;
}

.faq {
    width: 100%; 
    max-width: 1300px; 
    margin: 0 auto;
    padding: 0 15px;
}

.faq p {
    font-family: Roboto, sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    color: #434343;
}

.faq .faqTitle button {
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 25.78px;
    text-align: left;
    color: #656565;
    background-color: transparent;
}

.faq .faqItem {
    margin-top: 30px;
    border-radius: 15px;
    width: 100%; /* Make the item span the full width */
    border: 1px solid #E1E1E1;
}

.faq .header {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #000000;
    padding: 18px 0;
}

.faq .faqBody {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 21.09px;
    text-align: left;
    color: #000000;
    padding-right: 10%;
}

@media only screen and (max-width: 600px) {
    .faq p {
        font-size: 24px;
    }
    .faq .faqTitle button {
        font-size: 18px;
    }
    .faq .faqBody {
        font-size: 16px;
    }
}

.faq .faqFlex {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
