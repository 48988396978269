.blockTarif {
    display: flex;
    background: url(../../../images/fonTarrif.png) 0 0 repeat;
    justify-content: space-between;
    align-items:center;
    padding-bottom: 6%;
}
.blockTarif .card {
    margin: 10px;
    width: 257px;
    padding: 20px;
    gap: 0px;
    border: 0.5px 0px 0px 0px;
    border-radius:20px;
    color:#252525;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    position: relative;

}
.blockTarif .recom .options .footer .priceOptom h3
{
    color: black !important;
}
.blockTarif .card {
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.blockTarif .card:hover {
    border: 2px solid #ED8001;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
    transform: scale(1.05); 
    background-color: rgba(255, 255, 255, 0.1); 
}



.blockTarif .card:hover .options .img {
    display: none !important; 
}
.blockTarif .card .recom:hover .options .img1 {
    display: block !important; 
}

.blockTarif .card:hover .options .img1 {
    display: block !important; 
}
.blockTarif .card p {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
    line-height: 31.47px;
    text-align: left;
    color: #5E5E5E;

}
.blockTarif .recom h3 {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
    line-height: 31.47px;
    text-align: left;
    color: #5e5e5e;
}
.blockTarif .card .imgXL {
    width: 100%;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
}
.blockTarif .card .img {
    width: 100%;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 20px;
}

@media only screen and (max-width:1400px) {
    .blockTarif {
        flex-wrap: wrap;
        /* display: grid; */
        justify-content: center;
    }
    .blockTarif .card .img, .imgXL {
        display: none;
    } 
    .blockTarif .card li .img {
        display: flex;
        justify-content: flex-start;
        margin-bottom: -50px;
    } 
    .blockTarif .card {
        height: auto;
        width: 240px;
    }

    .blockTarif .recom {
        height: auto !important;
        width: 240px !important;
    }
    .options ol > li { 
        display: grid;
    }
    .blockTarif .card .imgS {
        margin-bottom: -50px;
    }
    .blockTarif .card .imgS {
         text-align: start;
    }
}

.blockTarif .card .img1 {
    width: 100%;
}
.blockTarif .card .imgS {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.card .options .img1 {
    display: none;
}
.options li {
    padding-bottom: 10px;
}
.card .options .img img {
    margin-top: 10px;
}
.card .options .img1 img {
    margin-top: 10px;
}
.card .options .imgS img {
    margin-top: 10px;
    padding-bottom: 10px;
}

.blockTarif .card .options .span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;

}
.blockTarif .card .options h4 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    line-height: 25.78px;
    text-align: left;
}

.options ol{
    list-style: none;
}
.options ol > li{
    padding-left: 20px;
    position: relative;
    margin-left: -25px;
}
.options ol > li:before{
    /* content: '✔'; */
    content: url('../../../images/ok.png');
    position: absolute; top: 0; left: 0;
    color: #181059;
}
.recom ol > li:before{
    /* content: '✔'; */
    content: url('../../../images/whiteOk.png');
    position: absolute; top: 0; left: 0;
    color: #ffffff !important;
}

.card .options .footer {
    display: grid;
    width: 100%;
    text-align: center;
    justify-content: center;
}
.card .options .footer .price {
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding-bottom: 15px;
}
.card .options .footer .price h3 {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.73px;
    text-align: center;
    padding-right: 10px;
}
.card .options .footer .Button {
    font-family: Roboto;
    width: 172px;
    height: 46px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    background:#42BBFF;
    border-color: #42BBFF;
    border-radius:30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}
.recom .options .footer .Button {
    font-family: Roboto;
    width: 172px;
    height: 46px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    background:#ED8001;
    border-radius:30px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}

.card .footer a {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 200;
    line-height: 18.75px;
    text-align: center;
    color: #252525;
    text-decoration: none;
    padding-top: 10%;
}
.recom .footer a {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 200;
    line-height: 18.75px;
    text-align: center;
    color: #5e5e5e;
    text-decoration: none;
    padding-top: 10%;
}
.recom 
{
    border:  2px solid #ED8001;
}

.recom .recBlock {
    width: 166.29px;
    height: 36.97px;
    top: -15px;
    gap: 0px;
    background:#ED8001;
    display: grid;
    justify-content: center;
    align-items:center;
    position: absolute;
    border-radius:20px;
    left: 16%;  
    right: 16%;

}
.recom .recBlock label {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.79px;
    text-align: left;
}
