.slick-slider {
  position: relative;
  width: 100%;
}

.slick-slide {
  width: 100%;
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
}

.slick-prev {
  left: 25px !important;
}

.slick-next {
  right: 25px !important;
}

.slick-dots {
  bottom: 25px !important;
  z-index: 1;
}

.slick-dots li button:before {
  color: white !important;
  font-size: 12px !important;
}

@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-prev {
    left: 15px !important;
  }

  .slick-next {
    right: 15px !important;
  }

  .slick-dots {
    bottom: 15px !important;
  }

  .slick-dots li button:before {
    font-size: 8px !important;
  }
}
