.Turbo .container {
    display: flex;
    justify-content: space-around;
    margin: 20px;
    flex-wrap: wrap;
  }
  
  .Turbo .container .option {
    border: 0.5px solid #5F5F5F;
    border-radius: 10px;
    padding: 20px;
    width: 260px;
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 20px;
  }
  .Turbo .container .option .flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    
    height: 45px;
    background-color: #f5f8fb;
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 10px;
  }
  .Turbo .container .option .flex .switch {
    
    width: 45px;
    height: 30px;


  }
  
  .Turbo .container  .title {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
    line-height: 31.47px;
    text-align: left;
    color: #5E5E5E;
    margin-bottom: 15px;
  }
  
  .Turbo .container  .speed-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .Turbo .container  .speed {
    font-size: 36px;
    color: #007bff;
  }
  
  .Turbo .container  .unit {
    font-size: 16px;
    color: #007bff;
  }
  
  .Turbo .container  .time-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .Turbo .container  .title-time-options {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #252525;
    padding-bottom: 5px;
  }
  
  .Turbo .container  .switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 20px;
    position: relative;
  }
  
  .Turbo .container  .switch input {
    display: none;
  }
  
  .Turbo .container  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ccc;
    border-radius: 34px;
  }
  
  .Turbo .container  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: white;
    transition: .4s;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
  }
  
  .Turbo .container  input:checked + .slider {
    background-color: #2196F3;
  }
  
  .Turbo .container  input:checked + .slider:before {
    transform: translate(20px, -50%);
  }
  
  .Turbo .container  .slider.round {
    border-radius: 34px;
  }
  
  .Turbo .container  .slider.round:before {
    border-radius: 50%;
  }
  
  .Turbo .container  .price {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: center;

    margin-bottom: 10px;
    color: #252525;
  }
  
  .Turbo .container .price span {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.73px;
    text-align: left;

  }
  
  .Turbo .container  .select-button {
    background-color: #009EE2;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50px;
    width: 173px;
    height: 46px;
    gap: 0px;
    /* opacity: 0px; */
    margin-bottom: 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;


  }
  
  .Turbo .container  .select-button:hover {
    background-color: #0056b3;
  }
  .Turbo .container  .footer-a {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 200;
    line-height: 18.75px;
    text-align: center;
    color: #252525;
    text-decoration: none;
    margin-top: 20px;
    
  }
  