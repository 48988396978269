.myNgn {
    background-color: #ffffff;
}

.fonNgn {
    height: 200px;
    top: 113px;
    gap: 0px;
    /* opacity: 0px; */
    background-color:#00A551;
    display: grid;
    align-items: center;
    padding-left: 10%;
}
.fonNgn h1 {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: 500;
    line-height: 65px;
    text-align: left;
    color: #ffffff;
}
.fonNgn h4 {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 300;
    line-height: 65px;
    text-align: left;
    color: #ffffff;
}