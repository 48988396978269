.select select {
    width: 261px;
    height: 47px;
    gap: 0px;
    border: 1px solid #B5B5B5;
    /* opacity: 0px; */
    border-radius: 30px;
    padding-left: 15px;
    font-family: Roboto;
    font-size: 21px;
    font-weight: 400;
    line-height: 24.61px;
    padding-right: 10px;
    color: #505050;
}