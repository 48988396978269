.myDomain {
    background-color:#ffffff;
}

.fonDomain {
    height: 402px;
    top: 111px;
    gap: 0px;
    /* opacity: 0px; */
    background-image:url(../images/domain/fonDomain.png);
    background-repeat:no-repeat;
    background-size: cover;
    background-position:center;
    display: flex;
    align-items: center;

    
}

.fonDomain h1 {
    font-family: Roboto;
    font-size: 96px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    padding-left: 20px;
    padding-left: 50px;
    color: #ffffff;
}

@media only screen and (max-width: 900px) { 
    .fonDomain { 
        height: 170px;
    }
    .fonDomain h1 {
        font-size: 60px;
    }
}