.Payment {
    background:#fff;
    padding-bottom: 60px;
    padding-top: 40px;
}
.myPay {
    height: 212px;
    background:#f69a30;
    border-radius:10px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-left: 5%;
    padding-right: 5%;
}

.myPay .textPay {
    padding-bottom: 40px;
}

.myPay .textPay h1 {
    font-family: Noto Sans;
    font-size: 60px;
    font-weight: 700;
    line-height: 81.72px;
    text-align: left;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); 
    margin-bottom: -10px;

}
.myPay .textPay span {
    font-family: Inter;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #ffffff;
}
.myPay .buttonPay .button {
    width: 166.86px;
    height: 62px;    
    gap: 0px;
    border-radius: 12px;
    /* opacity: 0px; */
    background:#ffffff;
    border:none;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    /* line-height: 28px; */
    text-align: center;
    color: #EB662B;

}
.myPay .buttonPay .img {
    padding-right: 30px;
}
.myPay .cardPay {
    position:absolute;
    left: 30%;
    bottom: 0;
}

@media only screen and (max-width:1250px) {
    .myPay {
        display: block;
        padding-top: 10px;
    }
    .myPay .cardPay .card1 {
       width: 300px;
       z-index: -9999;
        
    }
    .myPay .cardPay .card {
       width: 300px;
        
    }
    .myPay .textPay h1 {
        font-size: 36px;
        line-height: 40px;
        padding-bottom: 10px;
    }
    .myPay .buttonPay {
        display: flex;
        margin-left: -30px;
        padding: 5px;
        justify-content: space-between;
        position: absolute;
    }
}