.selectCity {
    display: flex;
    align-items: baseline;
    padding-top: 50px;
    padding-bottom: 20px;
}

.selectCity h4 {
    font-size: 30px;
    font-weight: 600;
    font-family: Roboto;
}

.selectCity select {
    border:none;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    color: #ED8001;
    background:transparent;
    padding-left: 15px;
    width: 200px;

}

@media only screen and (max-width:768px) {
    .selectCity select {
        font-size: 26px;
    }
    .selectCity h4 {
        font-size: 26px;
       
    }
    .allSelect {
        display: grid !important;
    }
    .allSelect .button {
        font-size: 16px !important;
    }
}
.selectCity select option {
    
/* font-weight: 600; */
/* line-height: 35.16px; */
text-align: left;
color: #ED8001;


}
.allSelect {
    display: flex;
    justify-content: space-between;
}

.allSelect .select  {
    display: grid;
}

 .allSelect .button {
    border:none;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 300 !important;
    line-height: 25.78px;
    text-align: left;
    color: #434343;
    padding-bottom: 10px;
    background: transparent;
}