.callMee {
    background-image: url('../../images/callMee/fonCall.png');
    background-blend-mode: overlay; 
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center; 
    width: 100%;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 3%;
}

.callBlock .callText p {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    color: #ffffff;
    padding-bottom: 20px;
}
.callBlock .callInput {
    padding-top: 20px;
    padding-bottom: 20px;
}

.callBlock .callInput input {
    width: 412.02px;
    height: 56px;
    gap: 0px;
    font-family: Roboto;
    font-size: 21px;
    font-weight: 400;
    line-height: 24.61px;
    text-align: left;
    padding-left: 10px;
    border-radius: 10px;
    border: none;
    margin-right: 40px;
}

.callBlock .callInput button {
    width: 182.03px;
    height: 56px;
    gap: 0px;
    background-color:#1E1F33;
    font-family: Noto Sans;
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    border: none;

}


@media only screen and (max-width:765px) {
    .callBlock .callInput button {
        margin-top: 20px;
    }
    .callBlock .callText p {
    font-size: 26px;
    }
    .callBlock .callInput button 
    {
       width: 100%;
    }
    .callBlock .callInput input
    {
        width: 100% !important;
    }
}

@media only screen and (max-width:500px) {
    .callBlock .callInput input {
        width: auto;
    }
  }

.callBlock .callFooter p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    color: #ffffff;
}