.additional-service .block-text {
    display: grid;
    padding-left:15px
}

.additional-service input {
    width: 24px;
    height: 24px;
    top: 1288px;
    left: 285px;
    gap: 0px;
    /* opacity: 0px; */

}
.additional-service input[type="number"] {
    width: 77px;
    height: 34px;
    border-radius: 10px;
    border: 0.5px solid #000000;
    /* opacity: 0px; */
  }
.additional-service .block-text label {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.58px;
    text-align: left;
    color: #545454;
    padding-bottom: 5px;
}
.additional-service .block-text span {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    line-height: 17.58px;
    text-align: left;
    color: #545454;
    padding-right: 60px;
}
.additional-service span {
    font-family: Roboto;
font-size: 12px;
font-weight: 400;
line-height: 14.06px;
text-align: left;
padding-left: 10px;
}