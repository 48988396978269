.domain-zones-container {
    font-family: 'Arial, sans-serif';
  }
.domain-zones-container h1 {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    color: #434343;
  }

  .table-container {
    overflow-x: auto; 
    max-width: 100%; 
  }
  
  .domain-zones-table {
    width: 100%;
    border-collapse: collapse; 
  }
  
  .domain-zones-table th,
  .domain-zones-table td {
    padding: 10px; 
    text-align: left;
    border: 1px solid transparent;
    border-bottom: 1px solid #94A3B8;
  }
  
  .zone-cell {
    position: relative; 
  }
  
  .zone {
    display: flex; 
    align-items: center;
  }
  
  .zone-icon {
    min-width: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #00A4D5;
    font-weight: 800;
    border: 1px solid #00A4D5;
    background-color: transparent;
    border-radius: 50%; 
  }
  
  .zone-name {
    font-weight: bold; 
  }
  
  .zone-description {
    color: #666; 
  }
  
  .apply-button {
    padding: 5px 10px; 
    background-color: #538bff;
    color: white; 
    border: none; 
    border-radius: 5px;
    cursor: pointer; 
  }
  
  .apply-button:hover {
    background-color: #4a79e0; 
  }
  
  .apply-button {
    padding: 8px 12px;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .apply-button-tj { background-color: #5DCCE8; }
  .apply-button-com { background-color: #ED8001; }
  .apply-button-org { background-color: #5DCCE8; }
  .apply-button-net { background-color: #5DCCE8; }
  .apply-button-uz { background-color: #5DCCE8; }
  
  .apply-button:hover {
    opacity: 0.8;
  }
  

  .security {
    margin-top: 80px;
  }

  @media only screen and (max-width: 900px) {
    .security .image {
      display: none;
    }
    .security .text label {
      font-size: 20px !important;
    }
    .security .text h2 {
      font-size: 26px !important;
      text-align: center !important;
    }

    .securCenter a {
      font-size: 20px !important;
    }
    th {
      font-size: 14px;
    }
    .domain-zones-table td { 
      font-size: 14px;
    }
    .zone-name {
      font-size: 14px;
      font-weight: bold;
    }
    .zone-icon { 
      width: 35px;
      height: 35px;
    }
    .zone-icon-tj {
      width: 35px;
      height: 35px;
      font-size: 15px;
     }
    .zone-icon-com {
      font-size: 14px;
     }
    .zone-icon-org {
      font-size: 14px;
     }
    .zone-icon-net {
      font-size: 14px;
     }
    .zone-icon-uz {
      width: 45px;
      height: 35px;
      font-size: 14px;
     }
     .apply-button {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 13px;
      line-height: normal;
     }

      
}
  .security .text h2 {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: left;
    color: #434343;
    padding-bottom: 50px;
  }
  .security .text label {
    font-family: 'Roboto';
    font-size: 29px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    color: #000000;
    padding-left: 30px;
    padding-right: 30px;
  }

  .securCenter {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

  }
  .securCenter a {
    font-family: 'Roboto';
    font-size: 26px;
    font-weight: 300;
    line-height: 30.47px;
    text-align: center;
    color: #000000;

  }