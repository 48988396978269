.myVps {
    background-color:#ffffff;
}

.fonVps {
    height: 402px;
    top: 111px;
    gap: 0px;
    background-color: #d5ddec;
    background-image:url(../images/vps/fonVps2.png),
    url(../images/vps/vpsFon3.png);
    background-repeat:no-repeat,
    no-repeat
    ;
    background-size: auto, auto;
    background-position:
    90% 50%, left;
    display: flex;
    align-items: center;

    
}