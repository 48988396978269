body {
  font-family: Noto Sans;
}

.menuServices {
    display: flex;
    padding-top: 50px;
}

.menuServices .row {
width: 100%;
border: 1px solid #CED4D7;
border-radius: 4px;
}
.col h4 {
  font-family: Noto Sans;
font-size: 18px;
font-weight: 500;
line-height: 30px;
text-align: left;

}
.col .textSale {
  color:#EB5757;
}

.col label {
font-size: 14px;
font-weight: 500;
line-height: 25px;
letter-spacing: -0.02em;
text-align: left;
text-decoration: underline;

}

.menuServices .row .col {
    display: flex;
    justify-content: space-evenly;
    place-items:center;
    max-height: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    

}
@media only screen and (max-width:1450px) {
  .menuServices .row .col {
    width: 90% !important;
    border:1px solid #CED4D7;
    margin: 5px;
  }
  .col .rotate {
    display: none;
  }
  .menuServices .row {
    border: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width:768px) {
  .menuServices .row .col {
    border:1px solid #CED4D7;
  }
  .col .rotate {
    display: none;
  }
  .menuServices .row {
    display: flex;
    flex-direction: column;
    text-align: center;
    }
}
.col .rotate {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 10;
    right: 20px;
    border-top:1px solid #CED4D7;
    border-right:1px solid #CED4D7;
    transform: rotate(45deg);
    background-repeat: no-repeat;
  }

.menuServices .row .col div{
  place-items: center;
}

.col .sale {
  display: grid;
  text-align: center;
}

.col .sale .sale1 {
  width: 59.33px;
  height: 30.47px;
  background-color: #EB5757;
  color:#fff;
}


