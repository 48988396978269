.lte {
    background-color:#ffffff;
}

.lte .fonHeader {
    height: 383px;
    background-color:#E72F2F;
    background-image: url(../images/LTE/fonLte.png);
    background-repeat:no-repeat;
    background-position: right;
    overflow: hidden;
    
}
@media only screen and (max-width:1400px) {
    .lte .fonHeader .flex .imgRouter {
       display: none;
    }
    .lte .fonHeader {
        display: grid;
        align-items: center;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media only screen and (max-width:800px) {
    .lte .fonHeader .headerText h4 {
        font-size: 30px !important;
        line-height: 30px !important;
    }
    .lte .fonHeader .headerText h1 {
        font-size: 60px !important;
        line-height: 30px !important;
    }
    .lte .fonHeader .flex .logo {
        padding-bottom: 10px;
        width: 150px;
    }
    .lte .fonHeader .flex .logo {
        padding-bottom: 0px !important;
    }
    .lte .fonHeader .flex .headerText {
        padding-bottom: 0px !important;
    }
}
@media only screen and (max-width:500px) {
    .lte .fonHeader .flex {
        display: grid !important;
    }
    .lte .fonHeader .flex .logo {
        padding-bottom: 20px !important;
    }
}

.lte .fonHeader .flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.lte .fonHeader .flex .logo {
    padding-bottom: 100px;
}
.lte .fonHeader .flex .headerText {
    padding-bottom: 100px;
}

.lte .fonHeader .headerText h4 {
    font-family: Roboto;
    font-size: 50px;
    font-weight: 300;
    line-height: 60px;
    text-align: left;
    color:#ffffff;
}

.lte .fonHeader .headerText h1 {
    font-family: Roboto;
    font-size: 75px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color:#ffffff;
}
.lte .fonHeader .flex .imgRouter {
    padding-top: 30px;
    
}